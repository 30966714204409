import { useMutation, useQuery } from "@apollo/client";
import { BASE_MEMBERSHIP_ANNUAL_PRICE, BASE_MEMBERSHIP_MONTHLY_PRICE } from "@app/shared/constants";
import { getMentorshipGroupAddOnPrice, priceAfterDiscount } from "@app/shared/prices";
import {
    MembershipBillingCycle,
    ProductFeature,
    Sangha,
    SanghaSessionCycle,
} from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Box, Button, Divider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_ADD_ON_MENTORSHIP_GROUP,
    GRAPHQL_QUERY_MEMBERSHIPS,
    GRAPHQL_QUERY_MENTORSHIP_GROUP_SANGHA_MEMBERSHIP,
    GRAPHQL_QUERY_MY_SANGHAS,
    GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS,
    GRAPHQL_QUERY_SANGHA_DETAILS,
    GRAPHQL_QUERY_SANGHA_DETAILS_PUBLIC,
    GRAPHQL_QUERY_USER,
} from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { CSCheckboxField } from "components/CSCheckboxField";
import { CSDialog } from "components/CSDialog";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { TeacherBioCard } from "components/TeacherBioCard";
import {
    fetchUser,
    selectHasLegacyCoreMembershipFeatures,
    selectIsLoggedIn,
} from "features/auth/auth";
import { NavLink } from "features/navigation/NavLink";
import { LoadingPage } from "features/pages/LoadingPage";
import { SanghaDescription } from "features/sangha/SanghaDescription";
import { useUserTimezone } from "hooks/useUserTimezone";
import { HeroWithImageBackground } from "layouts/HeroWithImageBackground";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import { getMentorshipGroupTitle } from "../community/shared";
import { SpotsAvailableInfo } from "../community/SpotsAvailableInfo";
import { MemberContext } from "../MemberContext";
import { membershipUsesFreeTrialInternally } from "../membership/membershipHelpers";

export const ReviewMentorshipAddOn = () => {
    const { memberships } = useContext(MemberContext);
    const membership = memberships[0];
    const history = useHistory();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isCoreMember = useSelector(selectHasLegacyCoreMembershipFeatures);

    const timezone = useUserTimezone();

    const { sanghaId } = useParams<{ sanghaId: string }>();

    const dispatch = useDispatch();

    const [addOnMentorshipGroup, { error: saveError, loading: saveLoading, data: saveData }] =
        useMutation(GRAPHQL_MUTATION_ADD_ON_MENTORSHIP_GROUP, {
            variables: {
                sanghaId,
            },
            update: () => {
                dispatch(fetchUser());
            },
            refetchQueries: [
                {
                    query: GRAPHQL_QUERY_USER,
                },
                {
                    query: GRAPHQL_QUERY_MEMBERSHIPS,
                },
                {
                    query: GRAPHQL_QUERY_MY_SANGHAS,
                },
                {
                    query: GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS,
                },
                {
                    query: GRAPHQL_QUERY_MENTORSHIP_GROUP_SANGHA_MEMBERSHIP,
                },
            ],
        });

    const [sanghaQuery, setSanghaQuery] = useState(
        isLoggedIn ? GRAPHQL_QUERY_SANGHA_DETAILS : GRAPHQL_QUERY_SANGHA_DETAILS_PUBLIC,
    );

    useEffect(() => {
        setSanghaQuery(
            isLoggedIn ? GRAPHQL_QUERY_SANGHA_DETAILS : GRAPHQL_QUERY_SANGHA_DETAILS_PUBLIC,
        );
    }, [isLoggedIn]);

    const {
        data: sanghaData,
        loading: sanghaLoading,
        error: sanghaError,
    } = useQuery(sanghaQuery, {
        variables: { id: sanghaId },
    });

    const sangha = sanghaData?.sangha as Sangha | undefined;

    const timeZone = useUserTimezone();

    useEffect(() => {
        if (saveData?.joinGroup?.id) {
            analyticsTrack("mentorshipGroup.addOn.success", {
                groupId: sanghaId,
            });
        }
    }, [saveData?.joinGroup?.id]);

    const isMemberOnFreeTrial = isLoggedIn && membershipUsesFreeTrialInternally(membership);
    const [freeTrialInfoPopupOpen, setFreeTrialInfoPopupOpen] = useState(false);
    const [commitmentPopupOpen, setCommitmentPopupOpen] = useState(false);
    const [guidelinesAccepted, setGuidelinesAccepted] = useState(false);
    const [chosenCycle, setChosenCycle] = useState<MembershipBillingCycle>(
        MembershipBillingCycle.year,
    );

    const handleCycleChange = (
        event: React.MouseEvent<HTMLElement>,
        newCycle: MembershipBillingCycle,
    ) => {
        if (newCycle !== null) {
            setChosenCycle(newCycle);
        }
    };

    const membershipCycle = membership?.billingChoice.plan.cycle;

    const isMonthlyPlan = (membershipCycle || chosenCycle) === MembershipBillingCycle.month;

    if (sanghaLoading || saveLoading) {
        return <LoadingPage />;
    }

    if (!sangha) {
        return (
            <GenericErrorPage alternateInstruction="We could not find the group you are looking for. Please" />
        );
    }

    if (sanghaError || saveError) {
        return <GenericErrorPage />;
    }

    if (saveData?.joinGroup?.id) {
        return <Redirect to={routes.memberHomePage()} />;
    }

    // If member is on a legacy plan or has multiple memberships, block them since we can't compute a price for them
    if (
        memberships.length > 1 ||
        (membership &&
            !membership?.billingChoice.plan.features.includes(
                ProductFeature.ELIGIBLE_FOR_ADD_ONS,
            )) ||
        (membership && membership.billingChoice.plan.available === false)
    ) {
        return (
            <PageWrapper>
                <Typography variant="body1">
                    Ah, you're on one of our legacy memberships. In order to add a mentorship group,
                    we'll need to switch your membership to our new one. Please{" "}
                    <NavLink to={routes.contactUs()}>contact support</NavLink> and we'll take care
                    of it.
                </Typography>
            </PageWrapper>
        );
    }

    if (!sangha.firstAvailableSessionDate) {
        return <GenericErrorPage />;
    }

    const firstSessionDate = dateTimeFromString(sangha.firstAvailableSessionDate, timeZone);
    const billingStartDate = firstSessionDate.minus({ weeks: 1 });

    const renderBillingStartDate = () => {
        if (billingStartDate < DateTime.now()) {
            return "immediately";
        }

        return `on ${billingStartDate.toLocaleString(DateTime.DATE_FULL)}`;
    };

    const renderCheckBulletPoint = (text: string, bold?: boolean) => {
        return (
            <Typography
                variant="body1"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 0.5,
                    fontWeight: `${bold ? "700" : "normal"}`,
                }}
            >
                <span
                    className={"material-symbols-rounded"}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    check
                </span>
                {text}
            </Typography>
        );
    };

    const renderPrice = () => {
        const membershipBasePrice = membership?.billingChoice.plan.baseAmount;
        const defaultBasePrice = isMonthlyPlan
            ? BASE_MEMBERSHIP_MONTHLY_PRICE
            : BASE_MEMBERSHIP_ANNUAL_PRICE;
        const basePrice = membershipBasePrice || defaultBasePrice;

        const addOnPrice = getMentorshipGroupAddOnPrice(
            sangha.eligibleMembershipTypes[0],
            membershipCycle || chosenCycle,
        );

        const totalPrice = basePrice + addOnPrice;

        let monthlyPrice = Math.round(totalPrice / (isMonthlyPlan ? 1 : 12));
        let annualPrice = isMonthlyPlan ? undefined : totalPrice;

        const discount = membership?.discount;
        if (discount && !discount.percentOff) {
            monthlyPrice = priceAfterDiscount(monthlyPrice, discount);
            annualPrice = annualPrice ? priceAfterDiscount(annualPrice, discount) : undefined;
        }

        return (
            <>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "baseline",
                    }}
                >
                    <Typography variant="h2" sx={{ mb: 0 }}>
                        ${monthlyPrice}
                    </Typography>
                    <Typography variant="h4">/month</Typography>
                </Box>

                <Typography variant="caption" sx={{ display: "block" }}>
                    <strong style={{ visibility: isMonthlyPlan ? "hidden" : "visible" }}>
                        Billed ${annualPrice}/year
                    </strong>
                </Typography>
            </>
        );
    };

    const handleEnrollment = async () => {
        localStorage.setItem("mentorshipGroupSignupGroupId", sanghaId);
        if (!isCoreMember) {
            localStorage.setItem("mentorshipGroupSignupFlow", "non-member");
            history.push(
                `${routes.signup()}?mentorshipPlan=${sangha.eligibleMembershipTypes[0]}&cycle=${
                    membershipCycle || chosenCycle
                }&groupId=${sanghaId}&isMentorshipOnly=true`,
            );
        } else if (isCoreMember) {
            localStorage.setItem("mentorshipGroupSignupFlow", "member");

            analyticsTrack("mentorshipGroup.addOn.submit", {
                firstSessionDate: firstSessionDate,
                groupId: sangha.id,
                teacherName: sangha.teacher.name,
            });
            await addOnMentorshipGroup();
        }
    };

    const renderBillingChangeDate = () => {
        return (
            <>
                <br />
                <Typography
                    variant="caption"
                    sx={{ color: theme.palette.grey700, fontWeight: 700 }}
                >
                    {isCoreMember
                        ? `Billing will change ${renderBillingStartDate()}`
                        : `Billing will start ${renderBillingStartDate()}`}
                </Typography>
            </>
        );
    };

    const renderCouponWarning = () => {
        return (
            !!membership?.discount?.percentOff && (
                <>
                    <br />
                    <Typography variant="caption" sx={{ mb: 0, color: theme.palette.warning500 }}>
                        Your current {membership?.discount?.percentOff}% discount will be removed
                        when you add a mentorship group.
                    </Typography>
                </>
            )
        );
    };

    const sanghaCycleUnit = sangha.cycle === SanghaSessionCycle.monthly ? "month" : "week";

    return (
        <>
            <HeroWithImageBackground imageUrl="https://assets.cloudsangha.co/images/plants.jpeg">
                <Box
                    sx={{
                        zIndex: 3,
                        display: "flex",
                        gap: {
                            xs: 2,
                            md: 8,
                        },
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        width: "100%",
                    }}
                >
                    {/* Text Container */}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box>
                            <Typography variant="h2" sx={{ mb: 0 }}>
                                {getMentorshipGroupTitle(sangha)} ({sangha.name})
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 0 }}>
                                Build confidence and get personalised support for your teaching and
                                your practice.
                            </Typography>
                        </Box>
                        <Box>
                            <SanghaDescription sangha={sangha} timeZone={timezone} invertColor />
                            <Typography variant="caption" sx={{ mb: 0 }}>
                                <strong>
                                    First session on{" "}
                                    {firstSessionDate.toLocaleString(DateTime.DATETIME_FULL)}
                                </strong>
                            </Typography>
                        </Box>
                        <SpotsAvailableInfo sangha={sangha} />
                    </Box>

                    {/* Membership Info Box */}
                    <Box
                        sx={{
                            backgroundColor: theme.palette.neutralCool,
                            borderRadius: 1,
                            p: 4,
                            minWidth: {
                                xs: "100%",
                                sm: 400,
                            },
                            color: theme.palette.grey900,
                        }}
                    >
                        <Typography variant="h6">
                            {isCoreMember ? "Your new billing plan" : "Membership"}
                        </Typography>
                        {!membership && (
                            <ToggleButtonGroup
                                exclusive
                                value={chosenCycle}
                                onChange={handleCycleChange}
                                color="secondary"
                                sx={{ mt: 1, mb: 0.5 }}
                            >
                                <ToggleButton value={MembershipBillingCycle.year}>
                                    Annual
                                </ToggleButton>
                                <ToggleButton value={MembershipBillingCycle.month}>
                                    Monthly
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        {renderPrice()}
                        <Divider sx={{ mt: 1, mb: 2 }} />
                        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                            {renderCheckBulletPoint("Dedicated mentorship group", true)}
                            {renderCheckBulletPoint("Community lounge with teacher Q&A", true)}
                            {renderCheckBulletPoint("Live teacher Q&A sessions", true)}
                        </Box>
                        <Button
                            variant="primary"
                            size="small"
                            fullWidth
                            data-testid="enrollButton"
                            onClick={() =>
                                isMemberOnFreeTrial
                                    ? setFreeTrialInfoPopupOpen(true)
                                    : setCommitmentPopupOpen(true)
                            }
                        >
                            Enroll now
                        </Button>

                        {renderBillingChangeDate()}
                        {renderCouponWarning()}
                    </Box>
                </Box>
            </HeroWithImageBackground>
            <PageWrapper smallPaddingTop>
                <Typography variant="h3" sx={{ mt: 4 }}>
                    Meet your teacher
                </Typography>
                <TeacherBioCard teacher={sangha.teacher} />
                <Typography variant="h3" sx={{ mt: 4 }}>
                    What your membership includes
                </Typography>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralCool,
                        borderRadius: 1,
                        p: 4,
                        color: theme.palette.grey900,
                    }}
                >
                    <Typography variant="subtitle2" sx={{ mb: 2 }}>
                        Join a mentorship group for deep practice based on your questions, needs,
                        and life circumstances.
                    </Typography>
                    <Typography variant="h6">Included</Typography>
                    <Divider sx={{ mt: 0.5, mb: 0.5 }} />
                    <Box sx={{ mb: 1 }}>
                        {renderCheckBulletPoint(
                            `Meet with the same teacher each ${sanghaCycleUnit} to build a close relationship and get personalised guidance.`,
                        )}
                        {renderCheckBulletPoint(
                            "Enter a closed mentorship group to build trust and relationships with other practitioners.",
                        )}
                        {renderCheckBulletPoint(
                            "Do the deep individual work that supports your ability to meet life as it is with peace and joy.",
                        )}
                        {renderCheckBulletPoint(
                            "Ask questions and receive guidance from members and teachers in the community lounge.",
                        )}
                        {renderCheckBulletPoint(
                            "Monthly live teacher Q&A sessions for real-time guidance.",
                        )}
                    </Box>
                    {!membership && (
                        <ToggleButtonGroup
                            exclusive
                            value={chosenCycle}
                            onChange={handleCycleChange}
                            color="secondary"
                            sx={{ mt: 1, mb: 0.5 }}
                        >
                            <ToggleButton value={MembershipBillingCycle.year}>Annual</ToggleButton>
                            <ToggleButton value={MembershipBillingCycle.month}>
                                Monthly
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    {renderPrice()}
                    <Button
                        variant="primary"
                        size="small"
                        onClick={() => setCommitmentPopupOpen(true)}
                        data-testid="enrollButton"
                    >
                        Enroll now
                    </Button>
                    {renderBillingChangeDate()}
                    {renderCouponWarning()}
                </Box>
            </PageWrapper>

            <CSDialog open={commitmentPopupOpen} onClose={() => setCommitmentPopupOpen(false)}>
                <Typography variant="h3">Make the commitment</Typography>
                <Typography variant="body1">
                    Mentorship groups are small, intimate groups of 8-12 people built on stability
                    and continuity. When you join a mentorship group, please honor the following 4
                    guidelines:
                </Typography>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    I agree to:
                </Typography>
                <Typography variant="body1">
                    <ul style={{ marginTop: 0 }}>
                        <li>
                            Show up for my first session, understanding my group will be expecting
                            me.
                        </li>
                        <li>
                            Attend the group regularly (except for illness, emergencies, vacations,
                            etc.)
                        </li>
                        <li>
                            Stay in the group for at least 4 sessions, to allow for continuity and
                            connection and because I understand the value I receive compounds over
                            time.
                        </li>
                        <li>
                            Let my group know when I decide to leave, and have a last session to
                            provide closure.
                        </li>
                        <li>
                            Commit to showing up because the value I receive compounds over time.
                        </li>
                    </ul>
                </Typography>

                <CSCheckboxField
                    label="I understand and agree to uphold these guidelines."
                    value={guidelinesAccepted}
                    onChange={setGuidelinesAccepted}
                    data-testid="acceptGuidelinesCheckbox"
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="primary"
                        onClick={handleEnrollment}
                        size="small"
                        disabled={!guidelinesAccepted}
                        data-testid="continueEnrollButton"
                    >
                        Continue and enroll
                    </Button>
                </Box>
            </CSDialog>
            <CSDialog
                open={freeTrialInfoPopupOpen}
                onClose={() => setFreeTrialInfoPopupOpen(false)}
            >
                <Typography variant="h3">
                    Your free trial will end & your paid membership will begin
                </Typography>
                <Typography variant="body1">
                    This is because mentorship groups are a paid-for addition to a basic membership.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 4 }}>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setFreeTrialInfoPopupOpen(false);
                            setCommitmentPopupOpen(true);
                        }}
                    >
                        Continue
                    </Button>
                    <Button variant="secondary" onClick={() => setFreeTrialInfoPopupOpen(false)}>
                        Cancel
                    </Button>
                </Box>
            </CSDialog>
        </>
    );
};
