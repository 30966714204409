import { SessionType } from "@app/shared/types";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { buttonClasses, createTheme, ThemeOptions } from "@mui/material";
import "@mui/material/styles";

// COLORS /////////////////////////////////////////////////////////////////////

const colors = {
    // Primary
    primaryBanyan: "#052625",
    primaryLeaves: "#1A423E",

    // Neutral
    neutralWhite: "#FFFFFF",
    neutralWarm: "#FFF7F2",
    neutralCool: "#EBF1E8",
    neutralGold: "#F9F4EC",

    // Accent
    accentGold: "#C89543",
    accentEarthy: "#BA6538",
    accentPeach: "#EBA384",
    accentPink: "#EB4C60",

    // Grey
    grey900: "#000000",
    grey800: "#2B2B2B",
    grey700: "#757679",
    grey400: "#A5A7AA",
    grey300: "#C8CBCE",
    grey200: "#E4E5E6",
    grey150: "#ECECEC",
    grey100: "#F8F8F8",

    // Actions
    error500: "#AE0A0A", // For errors & destructive actions
    error100: "#FFF2F1", // For error input backgrounds
    warning500: "#E08C02",
    warning100: "#FEF7B9",
    success500: "#027357",
    success100: "#DDFDED",

    // Calendar
    mentorship500: "#4295F8",
    mentorship100: "#ECF5FF",
    welcome500: "#85507B",
    welcome100: "#F0EAEF",
};

const borderWidth = "2px";
const borderStyle = "solid";

const borders = {
    default: `${borderWidth} ${borderStyle} ${colors.grey400}`,
};

const colorBorder = (color: string) => `${borderWidth} ${borderStyle} ${color}`;

const borderRadiuses = {
    small: 8,
    default: 30,
    large: 60,
    extraLarge: 200,
    circular: "50%",
};

const shadows = {
    default: "0px 0px 15px rgba(0, 0, 0, 0.14)",
    darker: "0 0 15px rgba(0, 0, 0, 0.25)",
    spread: "rgba(24, 24, 24, 0.5) 0px 10px 40px -10px",
};

type Colors = typeof colors;
type Borders = typeof borders;
type BorderRadiuses = typeof borderRadiuses;
type Shadows = typeof shadows;

declare module "@mui/material/styles/createPalette" {
    interface Palette extends Colors {}
    interface PaletteOptions extends Colors {}

    interface TypeBackground {
        contrast: string;
    }
}

declare module "@mui/material/styles" {
    interface Theme {
        border: Borders;
        borderRadius: BorderRadiuses;
        shadow: Shadows;
    }
    interface ThemeOptions {
        border?: Borders;
        borderRadius?: BorderRadiuses;
        shadow?: Shadows;
    }
}

const defaultTheme = createTheme({
    palette: {
        ...colors,

        primary: {
            main: colors.primaryBanyan,
        },
        secondary: {
            main: colors.grey900,
        },
        text: {
            primary: colors.grey900,
            secondary: colors.grey700,
        },
        background: {
            default: colors.neutralWhite,
            contrast: colors.neutralCool,
        },
        divider: colors.grey200,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1272, // 1440 - 200 margins on each side in design + 32 minimum margin
            xl: 1720,
        },
    },
    shape: {
        borderRadius: borderRadiuses.default,
    },
    border: borders,
    borderRadius: borderRadiuses,
    shadow: shadows,
});

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        primary: true;
        primaryInverse: true;
        secondary: true;
        secondaryInverse: true;
        tertiary: true;
        tertiaryInverse: true;
        destructive: true;
        destructiveInverse: true;
        optionBox: true;
    }
}

export const SessionTypeColors = {
    [SessionType.INTEREST_GROUP]: {
        default: defaultTheme.palette.accentEarthy,
        light: defaultTheme.palette.neutralWarm,
    },
    [SessionType.COMMUNITY_SIT]: {
        default: defaultTheme.palette.accentGold,
        light: defaultTheme.palette.neutralGold,
    },
    [SessionType.SILENT_SIT]: {
        default: defaultTheme.palette.warning500,
        light: defaultTheme.palette.neutralWarm,
    },
    [SessionType.COMMUNITY_TALK]: {
        default: defaultTheme.palette.primaryLeaves,
        light: defaultTheme.palette.neutralCool,
    },
    [SessionType.WELCOME_SESSION]: {
        default: defaultTheme.palette.welcome500,
        light: defaultTheme.palette.welcome100,
    },
    [SessionType.QA_SESSION]: {
        default: defaultTheme.palette.success500,
        light: defaultTheme.palette.neutralCool,
    },
    [SessionType.SANGHA_SESSION]: {
        default: defaultTheme.palette.mentorship500,
        light: defaultTheme.palette.mentorship100,
    },
};

declare module "@mui/material/Chip" {
    interface ChipPropsVariantOverrides {
        pink: true;
        green: true;
        lightGrey: true;
        darkGrey: true;
        darkGreen: true;
        interestGroup: true;
        communitySit: true;
        silentSit: true;
        communityTalk: true;
        welcomeSession: true;
        qaSession: true;
        sanghaSession: true;
    }
}

// TYPOGRAPHY /////////////////////////////////////////////////////////////////
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

declare module "@mui/material/styles" {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}

export const titleFont = ["Raleway", "serif"].join(",");

export const bodyFont = ["Lato", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",");

const themeOptions: ThemeOptions = {
    typography: {
        // default base font
        fontFamily: bodyFont,
        fontSize: 16,

        h1: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(48),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(2),

            textTransform: "uppercase",

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(28),
                marginBottom: defaultTheme.spacing(1),
            },
        },
        h2: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(36),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(1.5),

            textTransform: "uppercase",

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(22),
                marginBottom: defaultTheme.spacing(0.5),
            },
        },
        h3: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(24),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(1.5),

            textTransform: "uppercase",

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(16),
                marginBottom: defaultTheme.spacing(0.5),
            },
        },
        h4: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(26),

            lineHeight: 1.6,

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(20),
            },
        },
        h5: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(24),

            lineHeight: 1.6,

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(18),
            },
        },

        h6: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(16),

            lineHeight: 1.6,

            textTransform: "uppercase",

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(16),
            },
        },
        subtitle1: {
            fontFamily: titleFont,
            fontWeight: 400,
            fontSize: defaultTheme.typography.pxToRem(16),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(0.5),

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(16),
            },
        },

        subtitle2: {
            fontFamily: titleFont,
            fontWeight: 700,
            fontSize: defaultTheme.typography.pxToRem(20),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(0.5),

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(16),
            },
        },

        body1: {
            fontFamily: bodyFont,
            fontWeight: 400,
            fontSize: defaultTheme.typography.pxToRem(18),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(2),
        },

        body2: {
            fontFamily: bodyFont,
            fontWeight: 400,
            fontSize: defaultTheme.typography.pxToRem(24),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(1),

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(20),
            },
        },

        body3: {
            fontFamily: bodyFont,
            fontSize: defaultTheme.typography.pxToRem(13),
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: 1.2,

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(12),
            },
        },

        button: {
            fontFamily: bodyFont,
            fontWeight: 900,
            fontSize: defaultTheme.typography.pxToRem(16),

            lineHeight: 1.6,

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(14),
            },
        },

        caption: {
            fontFamily: bodyFont,
            fontWeight: 400,
            fontSize: defaultTheme.typography.pxToRem(16),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(0.5),

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(14),
            },
        },

        overline: {
            fontFamily: bodyFont,
            fontWeight: 500,
            fontSize: defaultTheme.typography.pxToRem(16),

            lineHeight: 1.6,

            marginBottom: defaultTheme.spacing(0.5),

            textTransform: "uppercase",

            display: "block",

            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: defaultTheme.typography.pxToRem(14),
            },
        },
    },
    // CUSTOM STYLES FOR COMPONENTS ///////////////////////////////////////////
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "primary" },
                    style: {
                        background: defaultTheme.palette.accentGold,
                        color: defaultTheme.palette.neutralWhite,

                        "&:hover": {
                            background: defaultTheme.palette.accentEarthy,
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.neutralWhite,
                        },
                    },
                },
                {
                    props: { variant: "primaryInverse" },
                    style: {
                        background: defaultTheme.palette.primaryBanyan,
                        color: defaultTheme.palette.neutralWhite,

                        "&:hover": {
                            background: defaultTheme.palette.accentGold,
                            color: defaultTheme.palette.neutralWhite,
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.neutralWhite,
                        },
                    },
                },
                {
                    props: { variant: "secondary" },
                    style: {
                        background: "transparent",
                        color: defaultTheme.palette.accentGold,
                        border: colorBorder(defaultTheme.palette.accentGold),

                        // Reducing the padding from the default button to balance out the added border
                        padding: defaultTheme.spacing(1.25, 3.75),
                        [`&.${buttonClasses.sizeSmall}`]: {
                            padding: defaultTheme.spacing(0.25, 3.75),
                        },

                        "&:hover": {
                            background: defaultTheme.palette.accentEarthy,
                            color: defaultTheme.palette.neutralWhite,
                            border: colorBorder(defaultTheme.palette.accentEarthy),
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.accentGold,
                        },
                    },
                },
                {
                    props: { variant: "secondaryInverse" },
                    style: {
                        background: "transparent",
                        color: defaultTheme.palette.neutralWhite,
                        border: colorBorder(defaultTheme.palette.neutralWhite),

                        // Reducing the padding from the default button to balance out the added border
                        padding: defaultTheme.spacing(1.25, 3.75),
                        [`&.${buttonClasses.sizeSmall}`]: {
                            padding: defaultTheme.spacing(0.25, 3.75),
                        },

                        "&:hover": {
                            background: defaultTheme.palette.accentGold,
                            color: defaultTheme.palette.neutralWhite,
                            border: colorBorder(defaultTheme.palette.accentGold),
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            background: "transparent",
                            color: defaultTheme.palette.neutralWhite,
                            border: colorBorder(defaultTheme.palette.neutralWhite),
                        },
                    },
                },
                {
                    props: { variant: "tertiary" },
                    style: {
                        color: defaultTheme.palette.accentGold,
                        textDecoration: "underline",
                        padding: 0,

                        minWidth: "auto",
                        maxWidth: "auto",

                        "&:hover": {
                            textDecoration: "underline",
                            color: defaultTheme.palette.accentEarthy,
                            background: "none",
                        },
                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.grey700,
                        },
                    },
                },
                {
                    props: { variant: "tertiaryInverse" },
                    style: {
                        color: defaultTheme.palette.neutralWhite,
                        textDecoration: "underline",
                        padding: 0,

                        minWidth: "auto",
                        maxWidth: "auto",

                        "&:hover": {
                            color: defaultTheme.palette.accentGold,
                            textDecoration: "underline",
                            background: "none",
                        },
                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.neutralWhite,
                        },
                    },
                },
                {
                    props: { variant: "destructive" },
                    style: {
                        background: defaultTheme.palette.error500,
                        color: defaultTheme.palette.neutralWhite,

                        "&:hover": {
                            background: defaultTheme.palette.grey800,
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.neutralWhite,
                        },
                    },
                },
                {
                    props: { variant: "destructiveInverse" },
                    style: {
                        background: defaultTheme.palette.error500,
                        color: defaultTheme.palette.neutralWhite,

                        "&:hover": {
                            background: defaultTheme.palette.grey800,
                        },

                        [`&.${buttonClasses.disabled}`]: {
                            color: defaultTheme.palette.neutralWhite,
                        },
                    },
                },
                {
                    props: { variant: "optionBox" },
                    style: {
                        maxWidth: "none",
                        width: "fit-content",
                        backgroundColor: defaultTheme.palette.grey100,
                        fontWeight: 600,
                        textTransform: "none",
                        padding: defaultTheme.spacing(0.5, 1.5),
                        color: defaultTheme.palette.grey700,
                    },
                },
            ],
            styleOverrides: {
                root: {
                    [`&.${buttonClasses}`]: {
                        ...defaultTheme.typography.button,
                    },
                    padding: defaultTheme.spacing(1.5, 4),
                    borderRadius: defaultTheme.borderRadius.large,
                    zIndex: defaultTheme.zIndex.speedDial,
                    minWidth: 150,
                    maxWidth: 350,

                    [`&.${buttonClasses.disabled}`]: {
                        opacity: 0.3,
                    },
                },
                fullWidth: {
                    maxWidth: "none",
                },
                sizeSmall: {
                    padding: defaultTheme.spacing(0.5, 4),
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                colorPrimary: {
                    color: defaultTheme.palette.primaryBanyan,

                    "&:hover": {
                        background: "none",
                        color: defaultTheme.palette.accentGold,
                    },

                    [`&.${buttonClasses.disabled}`]: {
                        color: defaultTheme.palette.primaryBanyan,
                        opacity: 0.3,
                    },
                },
                colorSecondary: {
                    background: defaultTheme.palette.accentGold,
                    color: defaultTheme.palette.neutralWhite,

                    "&:hover": {
                        background: defaultTheme.palette.accentEarthy,
                    },

                    [`&.${buttonClasses.disabled}`]: {
                        color: defaultTheme.palette.neutralWhite,
                        background: defaultTheme.palette.accentGold,
                        opacity: 0.3,
                    },
                },
                sizeSmall: {
                    padding: defaultTheme.spacing(0.75),
                },
                sizeMedium: {
                    padding: defaultTheme.spacing(1.5),
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    marginBottom: 0,
                },
                strong: {
                    fontWeight: 900,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "always",
                fontWeight: 700,
            },
            styleOverrides: {
                root: {
                    cursor: "pointer",
                    color: defaultTheme.palette.accentGold,
                    textDecorationColor: defaultTheme.palette.accentGold,
                    "&:hover": {
                        color: defaultTheme.palette.accentEarthy,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: defaultTheme.palette.accentGold,
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        color: defaultTheme.palette.accentGold,
                    },
                },
                colorSecondary: {
                    "&.Mui-checked": {
                        color: defaultTheme.palette.primaryBanyan,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: defaultTheme.borderRadius.small,
                    backgroundColor: defaultTheme.palette.neutralWhite,
                    "&.Mui-error": {
                        backgroundColor: defaultTheme.palette.error100,
                    },
                },
                notchedOutline: {
                    border: borders.default,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: defaultTheme.palette.error500,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: ExpandMoreRoundedIcon,
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: defaultTheme.palette.neutralWhite,
                    borderRadius: defaultTheme.borderRadius.default,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    margin: 0,
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: defaultTheme.palette.accentGold,
                    },
                    "&.Mui-selected": {
                        backgroundColor: defaultTheme.palette.grey200,
                        "&:hover": {
                            color: defaultTheme.palette.accentGold,
                        },
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    marginBottom: 0,
                },
            },
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: "pink" },
                    style: {
                        background: defaultTheme.palette.error100,
                        color: defaultTheme.palette.primaryBanyan,
                    },
                },
                {
                    props: { variant: "green" },
                    style: {
                        background: defaultTheme.palette.neutralCool,
                        color: defaultTheme.palette.primaryBanyan,
                    },
                },
                {
                    props: { variant: "lightGrey" },
                    style: {
                        background: defaultTheme.palette.grey200,
                        color: defaultTheme.palette.grey700,
                    },
                },
                {
                    props: { variant: "darkGrey" },
                    style: {
                        background: defaultTheme.palette.grey400,
                        color: defaultTheme.palette.neutralWhite,
                    },
                },
                {
                    props: { variant: "darkGreen" },
                    style: {
                        background: defaultTheme.palette.primaryLeaves,
                        color: defaultTheme.palette.neutralWhite,
                    },
                },
                {
                    props: { variant: "interestGroup" },
                    style: {
                        background: SessionTypeColors[SessionType.INTEREST_GROUP].light,
                        color: SessionTypeColors[SessionType.INTEREST_GROUP].default,
                    },
                },
                {
                    props: { variant: "communitySit" },
                    style: {
                        background: SessionTypeColors[SessionType.COMMUNITY_SIT].light,
                        color: SessionTypeColors[SessionType.COMMUNITY_SIT].default,
                    },
                },
                {
                    props: { variant: "silentSit" },
                    style: {
                        background: SessionTypeColors[SessionType.SILENT_SIT].light,
                        color: SessionTypeColors[SessionType.SILENT_SIT].default,
                    },
                },
                {
                    props: { variant: "communityTalk" },
                    style: {
                        background: SessionTypeColors[SessionType.COMMUNITY_TALK].light,
                        color: SessionTypeColors[SessionType.COMMUNITY_TALK].default,
                    },
                },
                {
                    props: { variant: "welcomeSession" },
                    style: {
                        background: SessionTypeColors[SessionType.WELCOME_SESSION].light,
                        color: SessionTypeColors[SessionType.WELCOME_SESSION].default,
                    },
                },
                {
                    props: { variant: "qaSession" },
                    style: {
                        background: SessionTypeColors[SessionType.QA_SESSION].light,
                        color: SessionTypeColors[SessionType.QA_SESSION].default,
                    },
                },
                {
                    props: { variant: "sanghaSession" },
                    style: {
                        background: SessionTypeColors[SessionType.SANGHA_SESSION].light,
                        color: SessionTypeColors[SessionType.SANGHA_SESSION].default,
                    },
                },
            ],
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    borderRadius: defaultTheme.borderRadius.small,
                    width: "fit-content",
                    height: "fit-content",
                },
                label: {
                    padding: 0,
                    fontWeight: 700,
                    textTransform: "uppercase",
                },
                icon: {
                    margin: 0,
                    paddingRight: defaultTheme.spacing(1),
                },
                sizeSmall: {
                    ...defaultTheme.typography.caption,
                    padding: defaultTheme.spacing(0.5, 1),
                },
                sizeMedium: {
                    ...defaultTheme.typography.body1,
                    padding: defaultTheme.spacing(1.75, 2),
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: defaultTheme.borderRadius.small,
                    fontSize: defaultTheme.typography.pxToRem(16),
                    [defaultTheme.breakpoints.down("md")]: {
                        fontSize: defaultTheme.typography.pxToRem(14),
                    },
                },
                filled: {
                    backgroundColor: defaultTheme.palette.neutralGold,
                    color: defaultTheme.palette.warning500,
                    "& .MuiAlert-icon": {
                        color: defaultTheme.palette.warning500,
                    },
                },

                outlinedInfo: {
                    borderColor: defaultTheme.palette.primaryLeaves,

                    "& .MuiAlert-icon": {
                        color: defaultTheme.palette.primaryLeaves,
                    },
                },
                action: {
                    alignItems: "center",
                    paddingBottom: defaultTheme.spacing(0.5),
                },
                icon: {
                    alignItems: "center",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: defaultTheme.palette.background.default,
                    borderRadius: defaultTheme.borderRadius.default,
                },
                paperFullScreen: {
                    borderRadius: 0,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                rounded: {
                    borderRadius: defaultTheme.borderRadius.default,
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    zIndex: defaultTheme.zIndex.modal - 1,

                    "& .MuiSnackbarContent-root": {
                        backgroundColor: defaultTheme.palette.accentGold,
                        color: defaultTheme.palette.neutralWhite,
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: defaultTheme.palette.neutralWhite,
                    boxShadow: "none",
                    "&.Mui-expanded": {
                        margin: 0,
                        "&:after": {
                            opacity: "1",
                            content: '""',
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: -1,
                            height: 1,
                            backgroundColor: defaultTheme.palette.grey200,
                        },
                    },
                    "&:first-child::after": {
                        content: '""',
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        height: 1,
                        backgroundColor: defaultTheme.palette.grey200,
                    },
                    "&:last-child:after": {
                        content: '""',
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        right: 0,
                        height: 1,
                        backgroundColor: defaultTheme.palette.grey200,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    "&.Mui-expanded": {
                        minHeight: 0,
                    },
                },
                content: {
                    margin: defaultTheme.spacing(1),
                    "&.Mui-expanded": {
                        margin: defaultTheme.spacing(1),
                    },
                    "& .MuiTypography-root": {
                        ...defaultTheme.typography.h6,
                        fontWeight: 600,
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    ...defaultTheme.typography.body1,
                    padding: 0,
                },
            },
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    variant: "body1",
                },
                secondaryTypographyProps: {
                    variant: "body1",
                },
            },
            styleOverrides: {
                primary: {
                    marginBottom: 0,
                    fontSize: defaultTheme.typography.pxToRem(16),
                },
                secondary: {
                    marginBottom: 0,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: defaultTheme.palette.accentGold,
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 36,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: defaultTheme.spacing(3, 0),
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    // color="primary"
                    "&.MuiToggleButton-primary": {
                        backgroundColor: defaultTheme.palette.grey150,
                        color: defaultTheme.palette.grey900,
                        border: "none",
                        padding: defaultTheme.spacing(0.75, 1.75),
                        "&:hover": {
                            backgroundColor: defaultTheme.palette.grey300,
                        },
                        "&.Mui-selected": {
                            backgroundColor: defaultTheme.palette.primaryLeaves,
                            color: defaultTheme.palette.neutralWhite,
                            "&:hover": {
                                backgroundColor: defaultTheme.palette.primaryLeaves,
                            },
                        },
                    },

                    // color="secondary"
                    "&.MuiToggleButton-secondary": {
                        backgroundColor: defaultTheme.palette.neutralWhite,
                        color: defaultTheme.palette.grey700,
                        border: `2px solid ${colors.grey200}`,
                        padding: defaultTheme.spacing(0.75, 1.75),
                        fontWeight: 600,
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: defaultTheme.palette.grey100,
                        },
                        "&.Mui-selected": {
                            backgroundColor: defaultTheme.palette.neutralGold,
                            color: defaultTheme.palette.accentGold,
                            fontWeight: 900,
                            padding: defaultTheme.spacing(0.75, 1.65),
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                placement: "top",
                arrow: true,
                enterDelay: 500,
            },
            styleOverrides: {
                popper: {
                    zIndex: defaultTheme.zIndex.speedDial + 1, // Used speedDial + 1 because our buttons use speedDial
                },
                tooltip: {
                    backgroundColor: defaultTheme.palette.primaryLeaves,
                    borderRadius: defaultTheme.borderRadius.small,
                    fontWeight: defaultTheme.typography.fontWeightBold,
                    fontSize: defaultTheme.typography.pxToRem(16),
                    [defaultTheme.breakpoints.down("md")]: {
                        fontSize: defaultTheme.typography.pxToRem(14),
                    },
                },
                arrow: {
                    color: defaultTheme.palette.primaryLeaves,
                },
            },
        },
    },
};

export const theme = createTheme(defaultTheme, themeOptions);
