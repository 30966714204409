import { useMutation } from "@apollo/client";
import { BRAND_NAME } from "@app/shared/constants";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_MUTATION_SEND_SUPPORT_TICKET } from "app/queries";
import { dictionaryValidator, emailValidator, stringValidator } from "app/validation";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { useFormBuilder } from "features/formBuilder/useFormBuilder";
import { useQueryParams } from "hooks/useQueryParams";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },

    input: {
        marginBottom: theme.spacing(2),
    },
}));

const TOPIC_OPTIONS = [
    `${BRAND_NAME} Membership`,
    "Mindfulness Daily",
    "Mindfulness Mentor Training Program",
    "Login Issue",
    "Cannot join my session",
    "Other",
];

interface ContactPageData {
    name: string;
    email: string;
    topic: string;
    message: string;
}

export const ContactPage = (props: {}) => {
    const classes = useStyles();

    const query = useQueryParams();
    const prefillMessage =
        typeof query.message === "string" ? decodeURIComponent(query.message) : undefined;

    const [sendSupportTicketMutation, { loading: isSaving, error: saveError }] = useMutation(
        GRAPHQL_MUTATION_SEND_SUPPORT_TICKET,
    );

    const { makeTextField, makeSelectField, onSubmit, updateField } =
        useFormBuilder<ContactPageData>({
            defaults: {
                name: "",
                email: "",
                topic: "",
                message: prefillMessage || "",
            },
            validationFunction: dictionaryValidator({
                email: emailValidator({ required: true }),
                name: stringValidator({ required: true }),
                topic: stringValidator({ required: true }),
                message: stringValidator({ required: true }),
            }),
            onSave: (data) => {
                sendSupportTicketMutation({
                    variables: data,
                })
                    .then(() => {
                        setMessageSent(true);
                        updateField("message", "");
                    })
                    .catch(() => {
                        // handle promise, error handled through saveError
                    });
            },
            textFieldProps: {
                className: classes.input,
            },
        });

    const [messageSent, setMessageSent] = useState(false);

    return (
        <PageWrapper className={classes.root}>
            <DocumentContext
                addBrandNameSuffix
                title="Contact Us"
                description="Learn more about our membership, mentorship groups, courses, becoming a teacher in our community, and more!"
            />
            <Typography variant="h1">Get In Touch</Typography>
            <Typography variant="body1">
                Have a question that's not answered on our website? Want to learn more about our
                membership, mentorship groups, courses, becoming a teacher in our community, or
                anything else? Send us a note below!
            </Typography>

            {messageSent ? (
                <Typography variant="body1" color="textPrimary">
                    Your message was sent! We'll be in touch.
                </Typography>
            ) : (
                <div>
                    {makeTextField("name", "Name")}
                    {makeTextField("email", "Email")}
                    {makeSelectField("topic", "Topic", TOPIC_OPTIONS)}
                    {makeTextField("message", "Message", {
                        multiline: true,
                        rows: 4,
                    })}

                    {saveError && (
                        <Typography variant="body2" color="error">
                            {saveError.message}
                        </Typography>
                    )}

                    <Button type="submit" variant="primary" disabled={isSaving} onClick={onSubmit}>
                        Send
                    </Button>
                </div>
            )}
        </PageWrapper>
    );
};

export default ContactPage;
