import { useQuery } from "@apollo/client";
import { ACTIVE_MENTORSHIP_GROUP_MEMBERSHIP_TYPES, MembershipStatus } from "@app/shared/constants";
import {
    Membership,
    MembershipBillingCycle,
    MembershipBillingPlan,
    MembershipType,
} from "@app/shared/types";
import { GRAPHQL_QUERY_MEMBERSHIPS, GRAPHQL_QUERY_SIGN_UP_BILLING_PLAN_OPTIONS } from "app/queries";
import { push } from "connected-react-router";
import LoadingPage from "features/pages/LoadingPage";
import { useQueryParams } from "hooks/useQueryParams";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { routes } from "../../app/routes";
import { RequireAccountCreation } from "./RequireAccountCreation";
import { StripeClientSecretProvider } from "./StripeClientSecretProvider";
import { useEffect, useState } from "react";
import { getSignupAnalyticsMetadataFromPlanName } from "./signupHelpers";
import { ChooseMonthlyOrAnnualPage } from "./ChooseMonthlyOrAnnualPage";

export const SignupFlow = () => {
    const dispatch = useDispatch();
    const query = useQueryParams();

    const { data: queryData } = useQuery(GRAPHQL_QUERY_SIGN_UP_BILLING_PLAN_OPTIONS);


    const [isMentorshipOnly, setIsMentorshipOnly] = useState<Boolean>();
    const [billingCycle, setBillingCycle] = useState<MembershipBillingCycle>();
    const [chosenPlan, setChosenPlan] = useState<MembershipBillingPlan | undefined>();

    const [allowedEmail] = useState(query.email as string | undefined);

    const { data: membershipData, loading: membershipsLoading } =
        useQuery(GRAPHQL_QUERY_MEMBERSHIPS);
    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeOrPending = memberships.filter((x) =>
        _.includes(MembershipStatus.ActiveOrPending, x.status),
    );

    const billingPlanOptions: MembershipBillingPlan[] | undefined =
        queryData?.signupBillingPlanOptions.filter((x: MembershipBillingPlan) => x.available);

    const mentorshipPlan =
        query.mentorshipPlan &&
        ACTIVE_MENTORSHIP_GROUP_MEMBERSHIP_TYPES.includes(query.mentorshipPlan as MembershipType)
            ? (query.mentorshipPlan as MembershipType)
            : undefined;

    const membershipType = mentorshipPlan || "explorer";

    useEffect(() => {
        if (billingPlanOptions && billingCycle && !chosenPlan) {
            const plan = billingPlanOptions.find(
                (plan) =>
                    plan.membershipType === membershipType &&
                    plan.cycle === billingCycle &&
                    (isMentorshipOnly ? !plan.addon : true), // If isMentorshipOnly is true, show only non-addon plans (i.e. the mentorship only plans). If isMentorshipOnly is false don't filter by addon status.
            );

            setChosenPlan(plan);
        }
    }, [billingCycle, billingPlanOptions, chosenPlan, membershipType, isMentorshipOnly]);

    if (activeOrPending.length > 0) {
        dispatch(push(routes.memberZone()));
        return <LoadingPage />;
    }

    if (query.cycle && !billingCycle) {
        setBillingCycle(query.cycle as MembershipBillingCycle);
    }

    if (query.isMentorshipOnly && !isMentorshipOnly) {
        setIsMentorshipOnly(!!query.isMentorshipOnly);
    }

    let couponCode: string | undefined;
    if (query.coupon && typeof query.coupon === "string") {
        couponCode = query.coupon;
    }

    const customSignupFlow = query.flow;
    if (typeof customSignupFlow === "string") {
        localStorage.setItem("signupFlow", customSignupFlow);
    }

    // Allow for a 30-day trial via custom link "?trialDays=30"
    const trialDays = query.trialDays === "30" ? 30 : undefined;

    if (membershipsLoading || !billingPlanOptions) {
        return <LoadingPage />;
    }

    const analyticsData = chosenPlan
        ? getSignupAnalyticsMetadataFromPlanName(chosenPlan.planName)
        : undefined;

    const selectedGroupId: string | undefined =
        typeof query.groupId === "string" ? query.groupId : undefined;

    const renderSignupStep = () => {
        if (billingCycle) {
            if (chosenPlan) {
                return (
                    <StripeClientSecretProvider
                        chosenPlan={chosenPlan}
                        trialDays={trialDays}
                        selectedGroupId={selectedGroupId}
                        couponCode={couponCode}
                    />
                );
            } else {
                return <LoadingPage />;
            }
        } else {
            return <ChooseMonthlyOrAnnualPage onSelect={setBillingCycle} />;
        }
    };

    return (
        <RequireAccountCreation
            analyticsPrefix="signup"
            analyticsData={analyticsData}
            allowedEmail={allowedEmail}
            optimizelyEventKey="explorerSignupViewed"
        >
            {renderSignupStep()}
        </RequireAccountCreation>
    );
};
