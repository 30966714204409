import { ProductFeature } from "@app/shared/types";
import { alpha, AppBar, Box, Slide, Toolbar, useScrollTrigger } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import classNames from "classnames";
import { Logo } from "components/Logo";
import { selectIsLoggedIn, selectIsMember, selectUser } from "features/auth/auth";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useDetectScroll } from "hooks/useDetectScroll";
import { useIsUserEnrolledInCourses } from "hooks/useIsUserEnrolledInCourses";
import { useQueryParams } from "hooks/useQueryParams";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, RouteProps, useLocation } from "react-router";
import { routes } from "../../app/routes";
import { selectCanAccessMemberZone } from "../auth/auth";
import { closeMenu, selectIsMenuOpen } from "./navigationSlice";
import { NavLink } from "./NavLink";
import { MENU_SHADOW_HEIGHT, TopBarMenu } from "./TopBarMenu";

const useStyles = makeStyles((theme) => ({
    appBar: {
        minHeight: NAVBAR_HEIGHT,
        overflow: "hidden",

        "&:before": {
            content: "''",
            position: "absolute",
            zIndex: -1,
            left: 0,
            bottom: MENU_SHADOW_HEIGHT,
            top: 0,
            right: 0,
            boxShadow: `${MENU_SHADOW_HEIGHT}px 0 ${MENU_SHADOW_HEIGHT}px ${alpha(
                theme.palette.grey900,
                0.1,
            )}`,
            transition: "opacity 500ms ease",
            opacity: 1,
        },

        [theme.breakpoints.down("lg")]: {
            minHeight: NAVBAR_HEIGHT_MOBILE,
        },
    },

    memberZoneAppBar: {
        "&:before": {
            opacity: 1,
        },
    },

    scrolledAppBar: {
        "&:before": {
            opacity: 1,
        },
    },
    scrolledPageFoldAppBar: {
        display: "none",
    },

    fullPageMenuOpen: {
        "&:before": {
            [theme.breakpoints.down("lg")]: {
                opacity: 0,
            },
        },
    },

    container: {
        padding: theme.spacing(0, 4),
        minHeight: NAVBAR_HEIGHT - MENU_SHADOW_HEIGHT,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.primaryBanyan,

        [theme.breakpoints.down("lg")]: {
            minHeight: NAVBAR_HEIGHT_MOBILE - MENU_SHADOW_HEIGHT,
        },
    },

    toolbar: {
        margin: "auto",
        width: "100%",
        justifyContent: "space-between",
        padding: theme.spacing(1, 0),
    },

    barSpace: {
        flexGrow: 1,
    },

    logoLink: {
        display: "flex",
        alignItems: "center",
        minHeight: NAVBAR_HEIGHT - MENU_SHADOW_HEIGHT,
    },
}));

const getPagesWithoutTopBar = (isActiveMember: boolean) => [
    // Signup & setup flow
    routes.signup(),
    routes.signupBilling(),
    routes.setupFlow(),
    routes.oauthAuthorize(),
    routes.courseSignup(":courseId"),
    routes.coursePaymentSuccess(":courseId"),
];

const PAGES_WITHOUT_INITIAL_APPBAR: Array<string | RouteProps> = [];

const getPagesWithoutMenu = (isActiveMember: boolean) => [
    routes.login(),
    routes.signup(),
    routes.teacherPostSession(""),
];

const hideAppbarInitially = (path: string): boolean => {
    return _.some(PAGES_WITHOUT_INITIAL_APPBAR, (route) => matchPath(path, route));
};

const skipMenu = (path: string, isActiveMember: boolean): boolean => {
    return _.some(getPagesWithoutMenu(isActiveMember), (route) => matchPath(path, route));
};

const skipTopBar = (path: string, isActiveMember: boolean): boolean =>
    _.some(getPagesWithoutTopBar(isActiveMember), (route) => matchPath(path, route));

export const TopBar = (props: { includeMenu?: boolean }): JSX.Element | null => {
    const classes = useStyles();
    const hasScrolled = useDetectScroll();
    const hasScrolledPageFold = useDetectScroll({
        threshold: window.innerHeight / 10,
    });

    const dispatch = useDispatch();
    const location = useLocation();
    const isMember = useSelector(selectIsMember);
    const fullPageMenuIsOpen = useSelector(selectIsMenuOpen);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);

    const user = useSelector(selectUser);

    const isActiveMember: boolean =
        user?.features?.some((feature: ProductFeature) =>
            [
                ProductFeature.CORE_MEMBERSHIP,
                ProductFeature.QA_SESSIONS,
                ProductFeature.DEDICATED_SANGHA,
            ].includes(feature),
        ) || false;

    const isEnrolledInCourses = useIsUserEnrolledInCourses();

    const memberZoneNavBar = isLoggedIn && canAccessMemberZone;

    const showMobileNavBar = useBreakpointQuery("lg");

    const query = useQueryParams();

    const { includeMenu = true } = props;
    const displayMenu =
        includeMenu && !query.hideNav && !skipMenu(location.pathname, isActiveMember);

    let logoTarget: string;

    if (isMember && !canAccessMemberZone && isEnrolledInCourses) {
        logoTarget = routes.coursesDashboard();
    } else if (canAccessMemberZone) {
        logoTarget = routes.memberHomePage();
    } else {
        logoTarget = routes.home();
    }

    if (skipTopBar(location.pathname, isActiveMember)) {
        return null;
    }

    const renderAppBar = (): JSX.Element => {
        return (
            <AppBar
                position={hideAppbarInitially(location.pathname) ? "fixed" : "sticky"}
                color="transparent"
                className={classNames(classes.appBar, {
                    [classes.memberZoneAppBar]: memberZoneNavBar,
                    [classes.scrolledAppBar]: hasScrolled,
                    [classes.fullPageMenuOpen]: fullPageMenuIsOpen,
                    [classes.scrolledPageFoldAppBar]:
                        hideAppbarInitially(location.pathname) && !hasScrolledPageFold,
                })}
                elevation={0}
            >
                <Box className={classes.container}>
                    <Toolbar disableGutters={true} className={classes.toolbar}>
                        <NavLink
                            color="textPrimary"
                            to={logoTarget}
                            additionalOnClick={() => dispatch(closeMenu())}
                            className={classes.logoLink}
                        >
                            <Logo
                                variant={showMobileNavBar ? "brandmark" : "logo"}
                                color="white"
                                margin="none"
                                height={60}
                                width={showMobileNavBar ? 60 : 120}
                            />
                        </NavLink>

                        {displayMenu && <TopBarMenu />}
                    </Toolbar>
                </Box>
            </AppBar>
        );
    };

    function HideOnScroll(props: { children: React.ReactElement }) {
        const { children } = props;
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: window.innerHeight / 10,
        });

        return (
            <Slide appear={true} direction="down" in={trigger} timeout={1250}>
                {children}
            </Slide>
        );
    }

    if (hideAppbarInitially(location.pathname)) {
        return (
            <>
                <HideOnScroll>{renderAppBar()}</HideOnScroll>
            </>
        );
    }

    return <>{renderAppBar()}</>;
};
