import { gql } from "@apollo/client";
import { ProductFeature, User, UserProfile } from "@app/shared/types";
import { isValidTimeZone } from "@app/shared/utils";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { DateTime } from "luxon";
import {
    GRAPHQL_MUTATION_LOGIN,
    GRAPHQL_MUTATION_REFRESH_AUTH_TOKEN,
    GRAPHQL_MUTATION_REQUEST_RESET_PASSWORD,
    GRAPHQL_QUERY_USER,
    USER_FIELDS,
} from "app/queries";
import { apolloClient } from "../../app/apollo";
import {
    createErrorLoggingAsyncThunk,
    defaultFetchedState,
    fetchedObjectFailedState,
    fetchedObjectFulfilledState,
    fetchedObjectReducers,
    FetchedObjectState,
} from "../../app/fetchers";
import { RootState } from "../../app/store";
import _ from "lodash";
import analytics from "../../app/analytics/analytics";
import { deleteFromStorage, writeStorage } from "@rehooks/local-storage";

export const fetchUser = createErrorLoggingAsyncThunk(
    "auth/fetchUser",
    async (___, thunkAPI): Promise<User> => {
        const result = await apolloClient.query({
            query: GRAPHQL_QUERY_USER,
            fetchPolicy: "network-only",
        });

        const user = result.data.me as User;

        // Store whether the user is a non member
        sessionStorage.setItem("userInfo", JSON.stringify(_.pick(user, "roles", "features")));

        if (!isValidTimeZone(user.timeZone)) {
            const browserTimeZone = DateTime.local().zoneName;
            thunkAPI.dispatch(updateAccount({ timeZone: browserTimeZone }));
            return Object.assign({}, user, { timeZone: browserTimeZone });
        }
        return user;
    },
);

export const updateAccount = createErrorLoggingAsyncThunk(
    "auth/updateAccount",
    async (params: { timeZone: string }, thunkAPI) => {
        const result = await apolloClient.mutate({
            mutation: gql`
                mutation UpdateAccount($timeZone: String) {
                    updateAccount(timeZone: $timeZone) {
                        ${USER_FIELDS}
                    }
                }
            `,
            variables: {
                timeZone: params.timeZone,
            },
        });

        // update the user on the front-end
        thunkAPI.dispatch(setUser(result.data.updateAccount));

        return result.data.updateAccount;
    },
);

export const refreshAuthToken = createAsyncThunk(
    "auth/refreshAuthToken",
    async (params: { refreshToken: string }, thunkAPI) => {
        try {
            const result = await apolloClient.mutate({
                mutation: GRAPHQL_MUTATION_REFRESH_AUTH_TOKEN,
                variables: {
                    refreshToken: params.refreshToken,
                },
            });

            return result.data.refreshAuthToken;
        } catch (e) {
            // log out if we can't refresh token
            await thunkAPI.dispatch(logout());
            throw e;
        }
    },
);
export interface LoginWithEmailParams {
    email: string;
    password: string;
    rememberMe: boolean;
    loginAs?: string;
}
export const loginWithEmail = createAsyncThunk(
    "auth/loginWithEmail",
    async (params: LoginWithEmailParams, thunkAPI) => {
        const result = await apolloClient.mutate({
            mutation: GRAPHQL_MUTATION_LOGIN,
            variables: {
                email: params.email,
                password: params.password,
            },
        });
        return {
            ...result.data.login,
            ..._.pick(params, "loginAs"),
        };
    },
);

export const requestResetPasswordLink = createErrorLoggingAsyncThunk(
    "auth/requestResetPasswordLink",
    async (params: { email: string }, thunkAPI) => {
        const result = await apolloClient.mutate({
            mutation: GRAPHQL_MUTATION_REQUEST_RESET_PASSWORD,
            variables: { email: params.email },
        });

        return result.data.requestPasswordReset;
    },
);

const getTokenFromSession = (tokenName: string) =>
    localStorage.getItem(tokenName) || sessionStorage.getItem(tokenName) || null;

const setTokenOnSession = (token: string | null, tokenName: string, persist?: boolean) => {
    if (token) {
        sessionStorage.setItem(tokenName, token);
        if (persist) {
            writeStorage(tokenName, token);
        } else {
            deleteFromStorage(tokenName);
        }
    } else {
        sessionStorage.removeItem(tokenName);
        deleteFromStorage(tokenName);
    }
};

const setAuthToken = (state: any, authToken: string | null, persist?: boolean) => {
    state.authToken = authToken;
    setTokenOnSession(authToken, "auth.token", persist);
};

const setRefreshToken = (state: any, refreshToken: string | null, persist?: boolean) => {
    state.refreshToken = refreshToken;
    setTokenOnSession(refreshToken, "auth.refreshToken", persist);
};

const setLoginAs = (state: any, loginAs: null | string) => {
    state.loginAs = loginAs;
    if (loginAs) {
        sessionStorage.setItem("loginAs", loginAs);
    } else {
        sessionStorage.removeItem("loginAs");
    }
};

const handleLogout = (state: any) => {
    state.user = defaultFetchedState();
    state.keepLoggedIn = false;
    setAuthToken(state, null);
    setRefreshToken(state, null);
    setLoginAs(state, null);

    // Stop any in-flight requests before we reset the store
    apolloClient.stop();
    // Clear apollo cache
    apolloClient.resetStore();

    // tell libraries who need to know
    Sentry.configureScope((scope) => scope.setUser(null));
    analytics.reset();
};

const initialState = {
    authToken: getTokenFromSession("auth.token"),
    refreshToken: getTokenFromSession("auth.refreshToken"),
    loginAs: sessionStorage.getItem("loginAs") || null,
    keepLoggedIn: false,
    loginError: null as string | null,
    signupError: null as string | null,
    user: defaultFetchedState() as FetchedObjectState<User>,
    resetPasswordLinkRequest: defaultFetchedState() as FetchedObjectState<string>,
    targetPath: (sessionStorage.getItem("loginTargetPath") || null) as string | null,
    targetParams: (sessionStorage.getItem("loginTargetParams") || null) as string | null,
    simulateMember: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        updateAuthToken: (
            state: any,
            action: PayloadAction<{ authToken: string; refreshToken?: string }>,
        ) => {
            const { authToken, refreshToken } = action.payload;
            setAuthToken(state, authToken);
            if (refreshToken) {
                setRefreshToken(state, refreshToken);
            }
        },

        logout: (state: any) => {
            handleLogout(state);
        },
        setLoginTargetPath: (
            state: any,
            action: PayloadAction<{
                targetPath: string;
                params?: string;
                hash?: string;
            }>,
        ) => {
            sessionStorage.setItem("loginTargetPath", action.payload.targetPath);
            if (action.payload.params) {
                sessionStorage.setItem("loginTargetParams", action.payload.params);
            }
            state.targetPath = action.payload.targetPath;
        },
        setSimulateMember: (state: any, action: PayloadAction<Boolean>) => {
            state.simulateMember = action.payload;
        },
        setUserProfile: (state: typeof initialState, action: PayloadAction<UserProfile>) => {
            if (state.user.result?.profile) {
                state.user.result.profile = action.payload;
            }
        },
        setUser: (state: typeof initialState, action: PayloadAction<User>) => {
            if (state.user) {
                state.user = fetchedObjectFulfilledState(action.payload);
            }
        },
    },
    extraReducers: {
        ...fetchedObjectReducers(fetchUser, (state) => state.user),
        [fetchUser.fulfilled.toString()]: (state: any, action: PayloadAction<User>) => {
            state.user = fetchedObjectFulfilledState(action.payload);
            // tell libraries who need to know about the user context
            const userResult = state.user.result as User;
            const traits: Record<string, string> = {
                id: userResult.id,
                email: userResult.email,
            };
            const locale = navigator.language;
            if (locale) {
                traits.locale = locale;
            }
            analytics.identify(userResult.id, traits);

            (window as any)._cio?.identify({
                id: userResult.email,
            });
            Sentry.setUser({ email: userResult.email, id: userResult.id });
        },
        [fetchUser.rejected.toString()]: (state: any, action: any) => {
            state.user = fetchedObjectFailedState(action.error);
            setAuthToken(state, null);
        },
        ...fetchedObjectReducers(
            requestResetPasswordLink,
            (state) => state.resetPasswordLinkRequest,
        ),
        [requestResetPasswordLink.rejected.toString()]: (state: any, action: any) => {
            state.resetPasswordLinkRequest = fetchedObjectFailedState(action.error.message);
        },
        [loginWithEmail.pending.toString()]: (state: any, action: any) => {},
        [loginWithEmail.fulfilled.toString()]: (
            state: any,
            action: PayloadAction<{ authToken: string; refreshToken: string; loginAs?: string }>,
        ) => {
            const remember = (action as any).meta.arg.rememberMe;
            state.error = null;
            state.keepLoggedIn = remember;
            setLoginAs(state, action.payload.loginAs || null);
            setAuthToken(state, action.payload.authToken, remember);
            setRefreshToken(state, action.payload.refreshToken, remember);
        },
        [loginWithEmail.rejected.toString()]: (state: any, action: any) => {
            state.authToken = null;
            state.loginError = action.error.message;
        },
        [refreshAuthToken.fulfilled.toString()]: (state: any, action: any) => {
            if (action.payload.authToken) {
                setAuthToken(state, action.payload.authToken, state.keepLoggedIn);
            }
        },
        [refreshAuthToken.rejected.toString()]: (state: any, action: any) => {
            handleLogout(state);
        },
    },
});

export const {
    updateAuthToken,
    logout,
    setLoginTargetPath,
    setSimulateMember,
    setUserProfile,
    setUser,
} = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.authToken;

export const selectAuthIsLoading = (state: RootState) =>
    Boolean(selectAuthToken(state)) && !selectUser(state);

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

export const selectAuthAndUserLoadCompleted = (state: RootState) =>
    !state.auth.authToken || state.auth.user.loaded;

export const selectUser = (state: RootState) => state.auth.user.result;

export const selectUserRoles = (state: RootState) => selectUser(state)?.roles || [];

export const selectUserFeatures = (state: RootState) => selectUser(state)?.features || [];

export const selectUserIsLoading = (state: RootState) => state.auth.user.loading;

export const selectUserId = (state: RootState) => selectUser(state)?.id || "";

export const selectUserProfile = (state: RootState) => selectUser(state)?.profile;

export const selectIsLoggedIn = (state: RootState) => Boolean(selectUser(state));

export const selectLoginError = (state: RootState) => state.auth.loginError;

export const selectSignupError = (state: RootState) => state.auth.signupError;

export const selectResetPasswordError = (state: RootState) =>
    state.auth.resetPasswordLinkRequest.error;

export const selectLoginTargetPath = (state: RootState) => state.auth.targetPath;

export const selectLoginTargetParams = (state: RootState) => state.auth.targetParams;

export const selectLoginAs = (state: RootState) => state.auth.loginAs;

export const selectIsAdmin = (state: RootState) =>
    !state.auth.simulateMember && _.includes(selectUserRoles(state), "admin");

export const selectIsTeacher = (state: RootState) => _.includes(selectUserRoles(state), "teacher");

export const selectLoggedInTeacherId = (state: RootState) => selectUser(state)?.teacher?.id;

export const selectIsTeacherOrAdmin = (state: RootState) =>
    selectIsTeacher(state) || selectIsAdmin(state);

export const selectIsMember = (state: RootState) =>
    !(selectIsAdmin(state) || selectIsTeacher(state));

export const selectIsEnrolledInCourses = (state: RootState) =>
    selectUserFeatures(state).includes(ProductFeature.ENROLLED_IN_COURSES);

export const selectHasLegacyCoreMembershipFeatures = (state: RootState) =>
    selectUserFeatures(state).includes(ProductFeature.CORE_MEMBERSHIP) ||
    selectIsTeacherOrAdmin(state);

export const selectCanAccessMemberZone = (state: RootState) =>
    selectUserFeatures(state).some((feature) =>
        [
            ProductFeature.CORE_MEMBERSHIP,
            ProductFeature.QA_SESSIONS,
            ProductFeature.DEDICATED_SANGHA,
        ].includes(feature),
    ) || selectIsTeacherOrAdmin(state);

export const selectRequestResetPasswordLinkComplete = (state: RootState) =>
    state.auth.resetPasswordLinkRequest.loaded && !state.auth.resetPasswordLinkRequest.error;

export default authSlice.reducer;
