import makeStyles from "@mui/styles/makeStyles";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
    markdown: {
        "& p": {
            margin: 0,
        },
    },
}));

export const MarkdownRenderer = ({ markdown }: { markdown: string }) => {
    const classes = useStyles();

    return <ReactMarkdown className={classes.markdown}>{markdown}</ReactMarkdown>;
};
