import { CircularProgress } from "@mui/material";
import { SxProps, Theme } from "@mui/material";

interface CircularProgressContainerProps {
    sx?: SxProps<Theme>;
}

export const CircularProgressContainer = (props: CircularProgressContainerProps) => {
    const { sx } = props;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
            }}
        >
            <CircularProgress sx={sx} />
        </div>
    );
};
