import { Sangha } from "@app/shared/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import { ImmersionGroupCard } from "../community/ImmersionGroupCard";

interface MentorshipGroupListProps {
    groups: Sangha[];
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

const separateGroups = (allGroups: Sangha[]) => {
    const { availableGroups, frozenGroups } = allGroups.reduce(
        (grouped, currentGroup) => {
            if (currentGroup.nextSession && currentGroup.firstAvailableSessionDate) {
                const nextSessionDate = DateTime.fromISO(currentGroup.nextSession.time).toMillis();
                const nextAvailableSessionDate = DateTime.fromISO(
                    currentGroup.firstAvailableSessionDate,
                ).toMillis();

                if (nextSessionDate === nextAvailableSessionDate) {
                    grouped.availableGroups.push(currentGroup);
                } else {
                    grouped.frozenGroups.push(currentGroup);
                }
            }
            return grouped;
        },
        { availableGroups: [] as Sangha[], frozenGroups: [] as Sangha[] },
    );

    return {
        availableGroups: availableGroups.sort((a, b) => b.openSpots - a.openSpots),
        frozenGroups: frozenGroups.sort((a, b) => b.openSpots - a.openSpots),
    };
};

export const MentorshipGroupList = (props: MentorshipGroupListProps) => {
    const { groups } = props;
    const classes = useStyles();

    const { availableGroups, frozenGroups } = separateGroups(groups);

    return (
        <>
            <div className={classes.cardContainer}>
                {availableGroups.map((group) => (
                    <ImmersionGroupCard key={group.id} sangha={group} />
                ))}
            </div>
            {frozenGroups.length > 0 && (
                <>
                    <Typography variant="h2" sx={{ mb: 0 }}>
                        Locked groups
                    </Typography>
                    <Typography variant="body1">
                        To foster group stability, we lock groups for 4 weeks when new members join.
                    </Typography>
                    <div className={classes.cardContainer}>
                        {frozenGroups.map((group) => (
                            <ImmersionGroupCard key={group.id} sangha={group} isFrozenGroup />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};
