import { useQuery } from "@apollo/client";
import { Session, SessionType } from "@app/shared/types";
import {
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    GRAPHQL_QUERY_UPCOMING_EVENTS,
    GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER,
} from "app/queries";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import { theme } from "app/theme";
import classNames from "classnames";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import DocumentContext from "components/DocumentContext";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import {
    selectHasLegacyCoreMembershipFeatures,
    selectLoggedInTeacherId,
    selectUserProfile,
} from "features/auth/auth";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { useServerTimeDelta } from "hooks/useServerTimeDelta";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { StringParam, useQueryParams } from "use-query-params";
import { CustomArrayParam } from "../filters/customQueryParamDefinitions";
import {
    filterSessions,
    getDayOfWeekFilterConfig,
    getOnlyMyEventsFilterConfig,
    getSessionTypeFilterConfig,
    getTeacherFilterConfig,
    getTeacherFilterOptionsFromSessions,
    getTimeOfDayFilterConfig,
    useInterestGroupTopicFilterConfig,
    useInterestGroupTopicFilterOptionsFromSessions,
} from "../filters/filterHelpers";
import { FilterConfig } from "../filters/FilterSidebar";
import { CalendarView, CalendarViewType } from "./CalendarView";
import { FilterDrawer } from "./FilterDrawer";
import { SessionsList } from "./SessionsList";
import { SettingsDrawer } from "./SettingsDrawer";

export enum SessionView {
    CALENDAR = "calendar",
    LIST = "list",
}

const CALENDAR_HEADER_HEIGHT = 67;
const CALENDAR_HEADER_HEIGHT_MOBILE = 52.5;
export const SESSIONS_PAGE_HEIGHT = `calc(100vh - ${CALENDAR_HEADER_HEIGHT + NAVBAR_HEIGHT}px)`;
export const SESSIONS_PAGE_HEIGHT_MOBILE = `calc(100vh - ${CALENDAR_HEADER_HEIGHT_MOBILE + NAVBAR_HEIGHT_MOBILE}px)`;

export const DRAWER_WIDTH = 260;

const useStyles = makeStyles((theme) => ({
    toggleButtonGroup: {
        border: `1px solid ${theme.palette.grey200}`,
        fontWeight: 100,
        height: 34,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            height: 32,
        },
    },
    toggleButton: {
        padding: "4px 16px",
        "&.Mui-selected": {
            color: theme.palette.accentGold,
            backgroundColor: "transparent",

            "&:hover": {
                backgroundColor: theme.palette.grey100,
            },
        },
        "&:hover": {
            backgroundColor: theme.palette.grey100,
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 8px",
        },
    },
    toggleButtonIcon: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
            marginRight: 0,
            fontSize: 22,
        },
    },
    filterIcon: {
        fontSize: 34,
        [theme.breakpoints.down("md")]: {
            fontSize: 28,
        },
    },
    mobileViewAndFiltersButtonWrapper: {
        display: "flex",
        gap: theme.spacing(1),
    },
    mobileViewAndFiltersButton: {
        backgroundColor: theme.palette.neutralWhite,
        color: theme.palette.grey800,
        fontWeight: 900,

        borderRadius: theme.borderRadius.large,
        border: `2px solid ${theme.palette.grey200}`,

        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        "&.active": {
            backgroundColor: theme.palette.grey100,
            color: theme.palette.accentGold,
        },

        "&:hover": {
            backgroundColor: theme.palette.grey100,
            cursor: "pointer",
        },
    },
}));

export const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "hasLegacyCoreMembership",
})<{
    open?: boolean;
    hasLegacyCoreMembership?: boolean;
}>(({ theme, open, hasLegacyCoreMembership }) => ({
    flexGrow: 1,
    position: "relative",
    overflow: "auto",
    ...(hasLegacyCoreMembership && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -DRAWER_WIDTH,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
}));

export const SessionsPage = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const timeDifferenceToServerTime = useServerTimeDelta();

    const currentUserProfile = useSelector(selectUserProfile);
    const hasLegacyCoreMembership = useSelector(selectHasLegacyCoreMembershipFeatures);
    const loggedInTeacherId = useSelector(selectLoggedInTeacherId);

    const includeTypes = hasLegacyCoreMembership
        ? [
              SessionType.COMMUNITY_SIT,
              SessionType.SILENT_SIT,
              SessionType.COMMUNITY_TALK,
              SessionType.TEACHER_ONLY_SESSION, // These are filtered out on the back-end for members
              SessionType.INTEREST_GROUP,
              SessionType.CONFLICT_RESOLUTION_PRACTICE,
              SessionType.QA_SESSION,
          ]
        : [SessionType.SANGHA_SESSION, SessionType.QA_SESSION];

    const [sessionView, setSessionView] = useState(
        hasLegacyCoreMembership ? SessionView.CALENDAR : SessionView.LIST,
    );
    const [calendarView, setCalendarView] = useState(
        isMobile ? CalendarViewType.THREEDAYS : CalendarViewType.WEEK,
    );

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(true);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
    const calendarRef = useRef(null);

    useEffect(() => {
        setIsFilterMenuOpen(!isMobile);
    }, [isMobile]);

    const timezone = useUserTimezone();

    const { data: rsvpedSessionData, refetch: refetchRsvpedSessions } = useQuery(
        GRAPHQL_QUERY_UPCOMING_RSVPED_SESSIONS_FOR_USER,
        {
            fetchPolicy: "network-only",
        },
    );

    const { data, loading, error } = useFetchDataAndKeepUpdated({
        query: GRAPHQL_QUERY_UPCOMING_EVENTS,
        options: {
            variables: {
                includeTypes,
                startingMonday: true,
                includeSanghaSessions: true,
            },
            fetchPolicy: "cache-and-network",
        },
        pollIntervalInMinutes: 30,
    });

    const upcomingRsvpedSessionsForUser = rsvpedSessionData?.upcomingRsvpedSessionsForUser as {
        id: string;
    }[];

    let events = data ? (Object.values(data)[0] as Session[]) : [];

    const teacherOptions = getTeacherFilterOptionsFromSessions(events);
    const [query, setQuery] = useQueryParams({
        onlyMyEvents: CustomArrayParam,
        sessionType: CustomArrayParam,
        teacher: CustomArrayParam,
        topic: CustomArrayParam,
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        view: StringParam,
    });

    const resetFilters = () => {
        setQuery({
            onlyMyEvents: undefined,
            sessionType: undefined,
            teacher: undefined,
            topic: undefined,
            timeOfDay: undefined,
            dayOfWeek: undefined,
        });
    };

    useEffect(() => {
        if (query.view && query.view !== sessionView) {
            if (query.view === "CALENDAR") {
                setSessionView(SessionView.CALENDAR);
            } else if (query.view === "LIST") {
                setSessionView(SessionView.LIST);
            }
        }
    }, []);

    const onlyMyEventsFilter = getOnlyMyEventsFilterConfig(query, setQuery);
    const sessionTypeFilter = getSessionTypeFilterConfig(query, setQuery);
    const topicOptions = useInterestGroupTopicFilterOptionsFromSessions(events);
    const topicFilter = useInterestGroupTopicFilterConfig(query, setQuery, topicOptions);
    const teacherFilter = getTeacherFilterConfig(query, setQuery, teacherOptions);

    const dayOfWeekFilter = getDayOfWeekFilterConfig(query, setQuery);
    const timeOfDayFilter = getTimeOfDayFilterConfig(query, setQuery);

    if (error) {
        return <GenericErrorMessage />;
    }

    if (
        !onlyMyEventsFilter ||
        !teacherFilter ||
        !sessionTypeFilter ||
        !topicFilter ||
        !dayOfWeekFilter ||
        !timeOfDayFilter
    ) {
        return <GenericErrorMessage />;
    }

    const filtersArray: FilterConfig[] = hasLegacyCoreMembership
        ? [
              onlyMyEventsFilter,
              sessionTypeFilter,
              teacherFilter,
              topicFilter,
              dayOfWeekFilter,
              timeOfDayFilter,
          ]
        : [];
    const numFiltersApplied = filtersArray.reduce(
        (total, filter) => total + filter.getAppliedCount(),
        0,
    );

    let filteredSessions = filterSessions(
        events,
        query,
        timezone,
        currentUserProfile,
        upcomingRsvpedSessionsForUser,
        loggedInTeacherId,
    );

    const handleSessionView = (
        event: React.MouseEvent<HTMLElement>,
        newSessionView: SessionView | null,
    ) => {
        if (newSessionView !== null) {
            setSettingsDrawerOpen(false);
            setSessionView(newSessionView);
        }
    };

    const renderSessionView = () => {
        if (loading) {
            return <CircularProgressContainer />;
        }

        if (sessionView === SessionView.CALENDAR) {
            return (
                <CalendarView
                    calendarRef={calendarRef}
                    events={filteredSessions}
                    isFilterMenuOpen={isFilterMenuOpen}
                    serverTimeDelta={timeDifferenceToServerTime}
                    onSessionAttend={refetchRsvpedSessions}
                    rsvpedSessions={upcomingRsvpedSessionsForUser}
                    calendarView={calendarView}
                    setCalendarView={setCalendarView}
                />
            );
        } else if (sessionView === SessionView.LIST) {
            return (
                <SessionsList
                    sessions={filteredSessions}
                    serverTimeDelta={timeDifferenceToServerTime}
                    rsvpedSessions={upcomingRsvpedSessionsForUser}
                    onSessionAttend={refetchRsvpedSessions}
                />
            );
        }
    };

    return (
        <div data-testid="sessionsPage">
            <DocumentContext title="Sessions" addBrandNameSuffix />

            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isMobile ? "space-between" : "initial",
                        borderBottom: "rgb(218,220,224) 1px solid",
                        padding: isMobile ? "4px 8px" : 0,
                    }}
                >
                    {hasLegacyCoreMembership && !isMobile && (
                        <Tooltip title={isFilterMenuOpen ? "Hide filters" : "Show filters"}>
                            <IconButton
                                onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                                aria-label="filter"
                            >
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.filterIcon,
                                    )}
                                >
                                    {isFilterMenuOpen
                                        ? "keyboard_double_arrow_left"
                                        : "keyboard_double_arrow_right"}
                                </span>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Typography
                        variant="h2"
                        sx={{ ml: !hasLegacyCoreMembership && !isMobile ? 2 : 0, mr: 2, mb: "0 !important" }}
                    >
                        Live Sessions
                    </Typography>
                    {hasLegacyCoreMembership &&
                        (!isMobile ? (
                            <ToggleButtonGroup
                                value={sessionView}
                                exclusive
                                onChange={handleSessionView}
                                aria-label="session view"
                                className={classes.toggleButtonGroup}
                            >
                                <ToggleButton
                                    value="calendar"
                                    aria-label="calendar"
                                    className={classes.toggleButton}
                                    data-testid="calendarToggleButton"
                                >
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.toggleButtonIcon,
                                        )}
                                    >
                                        calendar_today
                                    </span>
                                    <span style={{ fontWeight: 400, textTransform: "none" }}>
                                        Calendar
                                    </span>
                                </ToggleButton>
                                <ToggleButton
                                    value="list"
                                    aria-label="list"
                                    className={classes.toggleButton}
                                    data-testid="listToggleButton"
                                >
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.toggleButtonIcon,
                                        )}
                                    >
                                        sort
                                    </span>
                                    <span style={{ fontWeight: 400, textTransform: "none" }}>
                                        List
                                    </span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        ) : (
                            <div className={classes.mobileViewAndFiltersButtonWrapper}>
                                <button
                                    onClick={() => setSettingsDrawerOpen(!settingsDrawerOpen)}
                                    className={classNames(classes.mobileViewAndFiltersButton, {
                                        active: settingsDrawerOpen,
                                    })}
                                >
                                    Views
                                </button>
                                <button
                                    onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                                    className={classNames(classes.mobileViewAndFiltersButton, {
                                        active: isFilterMenuOpen,
                                    })}
                                >
                                    Filters ({numFiltersApplied})
                                </button>
                            </div>
                        ))}
                </div>

                <div
                    style={{
                        display: "flex",
                        height: isMobile ? SESSIONS_PAGE_HEIGHT_MOBILE : SESSIONS_PAGE_HEIGHT,
                    }}
                >
                    {hasLegacyCoreMembership && (
                        <FilterDrawer
                            filters={filtersArray}
                            resetFilters={resetFilters}
                            isFilterMenuOpen={isFilterMenuOpen}
                            numFiltersApplied={numFiltersApplied}
                            setIsFilterMenuOpen={setIsFilterMenuOpen}
                        />
                    )}
                    {hasLegacyCoreMembership && (
                        <SettingsDrawer
                            settingsDrawerOpen={settingsDrawerOpen}
                            setSettingsDrawerOpen={setSettingsDrawerOpen}
                            sessionView={sessionView}
                            handleSessionViewChange={handleSessionView}
                            calendarRef={calendarRef}
                            calendarView={calendarView}
                            setCalendarView={setCalendarView}
                        />
                    )}

                    <Main open={isFilterMenuOpen} hasLegacyCoreMembership={hasLegacyCoreMembership}>
                        {renderSessionView()}
                    </Main>
                </div>
            </div>
        </div>
    );
};
