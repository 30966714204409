import { useMutation, useQuery } from "@apollo/client";
import { MembershipStatus, SANGHA_MEMBERSHIP_STATUSES_ACTIVE } from "@app/shared/constants";
import { priceAfterDiscount } from "@app/shared/prices";
import {
    MembershipBillingCycle,
    MembershipBillingPlan,
    MembershipType,
    ProductFeature,
    SanghaType,
} from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import {
    Button,
    Chip,
    Divider,
    Drawer,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SiteFeature, isFeatureEnabled } from "app/featureFlags";
import {
    GRAPHQL_MUTATION_MEMBERSHIP_BILLING,
    GRAPHQL_QUERY_BILLING_PLANS_FROM_NAME,
} from "app/queries";
import { LIMITED_WIDTH, LIMITED_WIDTH_LARGE } from "app/styles";
import { theme } from "app/theme";
import classNames from "classnames";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import { CardToggle } from "components/CardToggle";
import { CSDialog } from "components/CSDialog";
import LoadingBar from "components/LoadingBar";
import { OptionBox } from "components/OptionBox";
import { push } from "connected-react-router";
import { selectHasLegacyCoreMembershipFeatures } from "features/auth/auth";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import BillingPlanExplanation from "features/billing/BillingPlanExplanation";
import { NavLink } from "features/navigation/NavLink";
import { useQueryParams } from "hooks/useQueryParams";
import { useUserTimezone } from "hooks/useUserTimezone";
import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../../app/routes";
import { MemberContext } from "../MemberZone";
import { useHasMentorshipGroup } from "../mySanghas/useHasMentorshipGroup";
import CancelledMembershipExplanation from "./CancelledMembershipExplanation";
import FreeTrialMembershipExplanation from "./FreeTrialMembershipExplanation";
import { membershipUsesFreeTrialInternally } from "./membershipHelpers";
import { MembershipNotFound } from "./MembershipNotFound";

const useStyles = makeStyles((theme) => ({
    billingLink: {
        display: "inline-block",
        marginRight: theme.spacing(2),
    },

    billingPlanExplanation: {
        maxWidth: LIMITED_WIDTH_LARGE,
    },
    cancelButton: {
        display: "block",
        marginBottom: theme.spacing(2),
        textAlign: "center",
        margin: "0 auto",
    },

    modalTitle: {
        marginTop: theme.spacing(2),
    },
    box: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(6),
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
    },
    optionBox: {
        margin: 0,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    drawer: {},
    drawerPaper: {
        width: 490,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    backButton: {
        padding: theme.spacing(2, 0, 1, 0),
    },
    drawerHeader: {
        boxShadow: theme.shadow.default,
        width: "100%",
        paddingLeft: theme.spacing(4),
    },
    drawerContent: {
        padding: theme.spacing(4),
    },
}));

export const MyMembership = (): JSX.Element => {
    const classes = useStyles();

    const queryParams = useQueryParams();
    const isCoreMember = useSelector(selectHasLegacyCoreMembershipFeatures);

    const [updateMembershipOrBillingOpen, setUpdateMembershipOrBillingOpen] = useState(false);
    const [isUpdateBillingPlanMenuOpen, setIsUpdateBillingPlanMenuOpen] = useState(false);

    useEffect(() => {
        if (
            queryParams.open &&
            queryParams.open === "updateBillingPlan" &&
            !isUpdateBillingPlanMenuOpen &&
            !updateMembershipOrBillingOpen
        ) {
            setUpdateMembershipOrBillingOpen(true);
            setIsUpdateBillingPlanMenuOpen(true);
        }
    }, [queryParams.open]);

    const memberContext = useContext(MemberContext);
    const activeMemberships = memberContext.memberships;
    const membership = _.first(activeMemberships);

    const currentPlan = membership?.billingChoice.plan;

    const hasMentorshipGroup = !!useHasMentorshipGroup();
    const isGraduateGroup =
        currentPlan?.membershipType === MembershipType.mmtcp ||
        membership?.scheduledChange?.billingChoice.plan.membershipType === MembershipType.mmtcp;

    const [cancelMentorshipGroupPopupOpen, setCancelMentorshipGroupPopupOpen] = useState(false);

    const hasLegacyMembership = !currentPlan?.available;
    const currentBillingCycle = currentPlan?.cycle;
    const futureBillingCycle = membership?.scheduledChange?.billingChoice?.plan.cycle;

    const [chosenBillingCycle, setChosenBillingCycle] = useState<
        MembershipBillingCycle | undefined
    >(hasLegacyMembership ? undefined : futureBillingCycle || currentBillingCycle);

    const [updateBillingPlan, { loading: updateBillingIsLoading }] = useMutation(
        GRAPHQL_MUTATION_MEMBERSHIP_BILLING,
    );

    const currentBillingPlans = [
        "baseAnnual",
        "explorerSlidingScaleMonthly49",
        "mentorshipTierAnnual2025",
        "immersionAddonMonthly150",
        "graduateMentorshipTierAnnual2025",
        "mmtcpAddonMonthly100",
        "mentorshipOnlyTierAnnual2025",
        "mentorshipOnlyTierMonthly150",
        "graduateMentorshipOnlyTierAnnual2025",
        "graduateMentorshipOnlyTierMonthly100",
    ];

    const { data: currentPlansData, loading: currentPlansLoading } = useQuery(
        GRAPHQL_QUERY_BILLING_PLANS_FROM_NAME,
        {
            variables: {
                planNames: currentBillingPlans,
            },
        },
    );

    const user = useContext(LoggedInUserProfileContext);
    const isImmersion = user.features.includes(ProductFeature.DEDICATED_SANGHA);
    const timezone = useUserTimezone();

    // Note; If there's a scheduled change, we show the future billing choice
    const billingChoice =
        membership && (membership.scheduledChange?.billingChoice || membership.billingChoice);

    const dispatch = useDispatch();

    if (currentPlansLoading) {
        return <LoadingBar />;
    }

    if (!membership || !billingChoice) {
        return <MembershipNotFound />;
    }

    const currentPlans = currentPlansData?.billingPlansFromName as MembershipBillingPlan[];

    let monthlyPlanName: string;
    let annualPlanName: string;
    let membershipProduct: string | undefined;
    let monthlyPrice: number | undefined;
    let yearlyPrice: number | undefined;
    let annualDiscount: string | undefined;
    let monthlyBillingPlan: MembershipBillingPlan | undefined;
    let annualBillingPlan: MembershipBillingPlan | undefined;

    if (!isCoreMember && isGraduateGroup) {
        // Graduate Mentorship Only Tier
        monthlyPlanName = "graduateMentorshipOnlyTierMonthly100";
        annualPlanName = "graduateMentorshipOnlyTierAnnual2025";
        monthlyBillingPlan = currentPlans.find((plan) => plan.planName === monthlyPlanName);
        annualBillingPlan = currentPlans.find((plan) => plan.planName === annualPlanName);
        membershipProduct = monthlyBillingPlan?.displayName;
        monthlyPrice = monthlyBillingPlan?.baseAmount;
        yearlyPrice = annualBillingPlan?.baseAmount;
        annualDiscount = annualBillingPlan?.displaySavings;
    } else if (!isCoreMember) {
        // Mentorship Only Tier
        monthlyPlanName = "mentorshipOnlyTierMonthly150";
        annualPlanName = "mentorshipOnlyTierAnnual2025";
        monthlyBillingPlan = currentPlans.find((plan) => plan.planName === monthlyPlanName);
        annualBillingPlan = currentPlans.find((plan) => plan.planName === annualPlanName);
        membershipProduct = monthlyBillingPlan?.displayName;
        monthlyPrice = monthlyBillingPlan?.baseAmount;
        yearlyPrice = annualBillingPlan?.baseAmount;
        annualDiscount = annualBillingPlan?.displaySavings;
    } else if (isCoreMember && hasMentorshipGroup && isGraduateGroup) {
        // Base membership + graduate mentorship group
        monthlyPlanName = "mmtcpAddonMonthly100";
        annualPlanName = "graduateMentorshipTierAnnual2025";
        monthlyBillingPlan = currentPlans.find((plan) => plan.planName === monthlyPlanName);
        annualBillingPlan = currentPlans.find((plan) => plan.planName === annualPlanName);
        membershipProduct = monthlyBillingPlan?.displayName;
        monthlyPrice = monthlyBillingPlan?.baseAmount;
        yearlyPrice = annualBillingPlan?.baseAmount;
        annualDiscount = annualBillingPlan?.displaySavings;
    } else if (isCoreMember && hasMentorshipGroup) {
        // Base membership + mentorship group
        monthlyPlanName = "immersionAddonMonthly150";
        annualPlanName = "mentorshipTierAnnual2025";
        monthlyBillingPlan = currentPlans.find((plan) => plan.planName === monthlyPlanName);
        annualBillingPlan = currentPlans.find((plan) => plan.planName === annualPlanName);
        membershipProduct = monthlyBillingPlan?.displayName;
        monthlyPrice = monthlyBillingPlan?.baseAmount;
        yearlyPrice = annualBillingPlan?.baseAmount;
        annualDiscount = annualBillingPlan?.displaySavings;
    } else {
        // Base membership
        monthlyPlanName = "explorerSlidingScaleMonthly49";
        annualPlanName = "baseAnnual";
        monthlyBillingPlan = currentPlans.find((plan) => plan.planName === monthlyPlanName);
        annualBillingPlan = currentPlans.find((plan) => plan.planName === annualPlanName);
        membershipProduct = monthlyBillingPlan?.displayName;
        monthlyPrice = monthlyBillingPlan?.baseAmount;
        yearlyPrice = annualBillingPlan?.baseAmount;
        annualDiscount = annualBillingPlan?.displaySavings;
    }

    const updateBillingPlanMutation = async () => {
        if (!membership || !chosenBillingCycle) {
            return;
        }

        const result = await updateBillingPlan({
            variables: {
                membershipId: membership.id,
                billingChoice: {
                    planName:
                        chosenBillingCycle === MembershipBillingCycle.year
                            ? annualPlanName
                            : monthlyPlanName,
                },
                removeDiscount: true,
            },
        });

        if (result.data) {
            showSnackbarAlertOnRedirect("Your billing plan was updated");
            window.location.reload();
        }
    };

    const { plan, donationAmount } = billingChoice;
    const showFreeTrialMessaging = membershipUsesFreeTrialInternally(membership);

    const isCancelledMembership = membership.status === MembershipStatus.PendingCancel;

    const activeImmersionSanghaMembership = membership.sanghaMemberships.find(
        (sanghaMembership) =>
            SANGHA_MEMBERSHIP_STATUSES_ACTIVE.includes(sanghaMembership.status) &&
            sanghaMembership.sangha.type === SanghaType.MentorshipGroup,
    );

    if (isCancelledMembership) {
        const endDate = isImmersion
            ? activeImmersionSanghaMembership?.endDate
            : membership.renewalDate;
        return (
            <CancelledMembershipExplanation
                cancelDate={DateTime.fromISO(endDate || membership.renewalDate)}
                isImmersion={isImmersion}
                classes={{
                    root: classes.billingPlanExplanation,
                }}
            />
        );
    }

    const renderFreeTrialBillingPlan = () => (
        <>
            <FreeTrialMembershipExplanation
                freeTrialEndDate={DateTime.fromISO(membership.freeTrialEndDate)}
                basePrice={priceAfterDiscount(plan.baseAmount, membership.discount)}
                billingPeriod={plan.billedEvery}
                classes={{
                    root: classes.billingPlanExplanation,
                }}
            />
        </>
    );

    const isMembershipScheduledToChange = () => membership.scheduledChange;

    const renderMembershipUpdateExplanation = () => {
        if (!membership.scheduledChange) {
            return null;
        }

        const futurePlan = membership.scheduledChange.billingChoice.plan;
        const changeDate = dateTimeFromString(
            membership.scheduledChange.changeDate,
            timezone,
        ).toLocaleString(DateTime.DATE_FULL);

        return (
            <Paper elevation={0} className={classes.box}>
                <Typography variant="body2">
                    Your current membership "
                    {currentPlan?.extendedDisplayName || currentPlan?.displayName}" will change to "
                    {futurePlan.extendedDisplayName || futurePlan.displayName}" on {changeDate}.
                </Typography>
            </Paper>
        );
    };

    const renderMultipleMembershipsExplanation = () => {
        return (
            <Paper elevation={0} className={classes.box}>
                <Typography variant="body2">
                    You have multiple active memberships - please{" "}
                    <NavLink to={routes.contactUs()}>contact us</NavLink> for more information.
                </Typography>
            </Paper>
        );
    };

    const renderBillingPlanDetails = () => {
        if (showFreeTrialMessaging) {
            return renderFreeTrialBillingPlan();
        } else if (isMembershipScheduledToChange()) {
            return renderMembershipUpdateExplanation();
        } else {
            return (
                <BillingPlanExplanation
                    plan={plan}
                    donationPrice={donationAmount}
                    renewalDate={DateTime.fromISO(membership.renewalDate)}
                    membership={membership}
                    classes={{
                        root: classes.billingPlanExplanation,
                    }}
                />
            );
        }
    };

    const renderMembershipSettings = () => {
        return (
            <>
                <OptionBox
                    onClick={() =>
                        window.open(
                            `https://banyantogether.typeform.com/scholarship#stripe_subscription_id=${membership.stripeSubscriptionId}`,
                            "_blank",
                        )
                    }
                >
                    <Typography variant="button" className={classes.optionBox}>
                        Request a scholarship
                    </Typography>
                </OptionBox>

                <OptionBox onClick={() => dispatch(push(routes.memberUpdatePaymentMethod()))}>
                    <Typography variant="button" className={classes.optionBox}>
                        Update my card
                    </Typography>
                </OptionBox>

                {isFeatureEnabled(SiteFeature.AnnualBillingPlan) && (
                    <OptionBox onClick={() => setIsUpdateBillingPlanMenuOpen(true)}>
                        <Typography variant="button" className={classes.optionBox}>
                            Update billing plan
                        </Typography>
                    </OptionBox>
                )}

                <Divider />

                <OptionBox
                    onClick={() => {
                        window.location.href = `https://banyantogether.typeform.com/cancel#email=${membership.email}&membership_id=${membership.id}&stripe_subscription_link=https://dashboard.stripe.com/subscriptions/${membership.stripeSubscriptionId}`;
                    }}
                >
                    <Typography variant="button" className={classes.optionBox}>
                        Cancel entire membership
                    </Typography>
                </OptionBox>

                {hasMentorshipGroup && (
                    <OptionBox onClick={() => setCancelMentorshipGroupPopupOpen(true)}>
                        <Typography variant="button">Cancel mentorship group</Typography>
                    </OptionBox>
                )}
            </>
        );
    };

    const handleBackClick = () => {
        isUpdateBillingPlanMenuOpen
            ? setIsUpdateBillingPlanMenuOpen(false)
            : setUpdateMembershipOrBillingOpen(false);
    };

    const renderDrawerHeaderTitle = () => {
        return isUpdateBillingPlanMenuOpen ? "Update Billing Plan" : "Membership Settings";
    };

    const renderUpdateBillingPlanMenu = () => {
        if (updateBillingIsLoading) {
            return <LoadingBar />;
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", gap: theme.spacing(2) }}>
                <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
                    {membershipProduct}
                </Typography>
                {hasLegacyMembership && (
                    <Typography variant="body1">
                        You are still on a legacy billing plan. Please switch to one of our current
                        plans below.
                    </Typography>
                )}
                {!!membership.discount && (
                    <Typography variant="body1" sx={{ mb: 0 }}>
                        You're currently on a discounted or scholarship membership. Discounts can
                        not be combined. Switching to the annual membership will replace your
                        existing discount with the annual discounted rate.
                    </Typography>
                )}
                <CardToggle
                    isSelected={chosenBillingCycle === MembershipBillingCycle.month}
                    onClick={() => setChosenBillingCycle(MembershipBillingCycle.month)}
                >
                    <div>
                        <Typography variant="h3" sx={{ mb: 0 }}>
                            Monthly
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            ${monthlyPrice}/month
                        </Typography>
                    </div>
                </CardToggle>
                <CardToggle
                    isSelected={chosenBillingCycle === MembershipBillingCycle.year}
                    onClick={() => setChosenBillingCycle(MembershipBillingCycle.year)}
                >
                    <div>
                        <Typography
                            variant="h3"
                            sx={{
                                mb: 0,
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(1),
                            }}
                        >
                            Annual <Chip variant="darkGreen" label={`Save ${annualDiscount}`} />
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            ${Math.round((yearlyPrice || 0) / 12)}/month, billed ${yearlyPrice}
                        </Typography>
                    </div>
                </CardToggle>
                <Button
                    variant="primary"
                    sx={{ mt: 1 }}
                    onClick={() => updateBillingPlanMutation()}
                    fullWidth
                    disabled={
                        (!hasLegacyMembership &&
                            chosenBillingCycle === (futureBillingCycle || currentBillingCycle)) ||
                        !chosenBillingCycle
                    }
                >
                    Update Billing Plan
                </Button>
            </div>
        );
    };

    const renderUpdateMembershipOrBillingDialog = () => (
        <Drawer
            onClose={() => {
                setUpdateMembershipOrBillingOpen(false);
            }}
            open={updateMembershipOrBillingOpen}
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor="right"
        >
            <div className={classes.drawerHeader}>
                <Tooltip title="Close membership settings">
                    <IconButton
                        onClick={() => handleBackClick()}
                        aria-label="go back"
                        className={classes.backButton}
                    >
                        <span className={classNames("material-symbols-rounded")}>arrow_back</span>
                    </IconButton>
                </Tooltip>
                <Typography variant="h3" className={classes.header}>
                    {renderDrawerHeaderTitle()}
                </Typography>
            </div>

            <div className={classes.drawerContent}>
                {isUpdateBillingPlanMenuOpen
                    ? renderUpdateBillingPlanMenu()
                    : renderMembershipSettings()}
            </div>
        </Drawer>
    );
    const renderUpdateMembershipOrBillingLink = () => (
        <Button
            variant="secondary"
            className={classes.billingLink}
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                setUpdateMembershipOrBillingOpen(true);
            }}
        >
            Membership Settings
        </Button>
    );

    const renderCancelMentorshipGroupPopup = () => {
        return (
            <CSDialog
                open={cancelMentorshipGroupPopupOpen}
                onClose={() => setCancelMentorshipGroupPopupOpen(false)}
            >
                <Typography variant="h2">Mentorship Group Cancellation</Typography>
                <Typography variant="body1">
                    To cancel your mentorship group, please{" "}
                    <NavLink to={routes.contactUs()}>contact our support team</NavLink>.
                </Typography>
            </CSDialog>
        );
    };

    if (activeMemberships.length > 1) {
        return renderMultipleMembershipsExplanation();
    }

    return (
        <>
            {renderBillingPlanDetails()}
            {renderUpdateMembershipOrBillingDialog()}
            {renderUpdateMembershipOrBillingLink()}
            {renderCancelMentorshipGroupPopup()}
        </>
    );
};
